import masterData from './master-data'
import transactions from './transactions'
import report from './report'

export default [
  {
    title: 'Dashboard',
    route: 'dashboard-analytics',
    icon: 'HomeIcon',
    resource: 'AUTH',
    action: 'read'
  },
  ...masterData,
  ...transactions,
  ...report
]
