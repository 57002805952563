import { subjectAbility } from '@/constant'

/*
 * title : get from @/libs/i18n/locales/[id / en].json
 * in purpose to support multilanguage
 */

export default [
  {
    header: 'Transactions',
    resource: subjectAbility.Tx,
    action: 'read'
  },
  {
    title: 'apps.transactions.sales.singular.sales',
    icon: 'BookIcon',
    children: [
      {
        title: 'apps.transactions.sales.invoice.moduleName',
        route: 'apps-transactions-sales-invoice-list',
        resource: subjectAbility.TxSalesInvoice,
        action: 'read'
      }
    ]
  },
  {
    title: 'apps.transactions.purchase.singular.purchase',
    icon: 'BookIcon',
    children: [
      {
        title: 'apps.transactions.purchase.invoice.moduleName',
        route: 'apps-transactions-purchase-invoice-list',
        resource: subjectAbility.TxSalesInvoice,
        action: 'read'
      }
    ]
  },
  {
    title: 'apps.transactionsJournal.moduleName',
    route: 'apps-transactions-journal-list',
    icon: 'BookIcon',
    resource: subjectAbility.TxJournal,
    action: 'read'
  },
  {
    title: 'apps.transactions.chbacc.transaction.moduleName',
    route: 'apps-transactions-chbacc-transaction-list',
    icon: 'SunriseIcon',
    resource: subjectAbility.ChBaccTransaction,
    action: 'read'
  },
  {
    title: 'apps.transactionsCashbank.moduleName',
    route: 'apps-transactions-cashbank-list',
    icon: 'BookIcon',
    resource: subjectAbility.TxCashbank,
    action: 'read'
  },
  {
    title: 'apps.transactionsCashAdvanceRequestApproval.moduleName',
    route: 'apps-transactions-cash-advance-request-approval-list',
    icon: 'BookIcon',
    resource: subjectAbility.TxCashAdvanceRequestApproval,
    action: 'read'
  },
  {
    title: 'apps.transactionsCashAdvance.moduleName',
    route: 'apps-transactions-cash-advance-list',
    icon: 'BookIcon',
    resource: subjectAbility.TxCashAdvance,
    action: 'read'
  },
  {
    title: 'apps.transactionsCashAdvanceRequest.moduleName',
    route: 'apps-transactions-cash-advance-request-list',
    icon: 'BookIcon',
    resource: subjectAbility.TxCashAdvanceRequest,
    action: 'read'
  },
  {
    title: 'apps.budgeting.moduleName',
    route: 'apps-transactions-budgeting-list',
    icon: 'BookIcon',
    resource: subjectAbility.TxBudgeting,
    action: 'read'
  }
]
