import { subjectAbility } from '@/constant'

/*
 * title : get from @/libs/i18n/locales/[id / en].json
 * in purpose to support multilanguage
 */

export default [
  {
    header: 'Master Data',
    resource: subjectAbility.Md,
    action: 'read'
  },
  {
    title: 'apps.masterDataCOA.moduleName',
    route: 'apps-master-data-coa-list',
    icon: 'BookIcon',
    resource: subjectAbility.MdCoa,
    action: 'read'
  },
  {
    title: 'apps.masterDataContact.moduleName',
    route: 'apps-master-data-contact-list',
    icon: 'FileIcon',
    resource: subjectAbility.MdContact,
    action: 'read'
  },
  {
    title: 'apps.masterDataEmployee.moduleName',
    route: 'apps-master-data-employee-list',
    icon: 'FileIcon',
    resource: subjectAbility.MdEmployee,
    action: 'read'
  },
  {
    title: 'apps.masterDataUser.moduleName',
    route: 'apps-master-data-user-list',
    icon: 'FileIcon',
    resource: subjectAbility.MdUser,
    action: 'read'
  },
  {
    title: 'apps.masterDataProduct.moduleName',
    route: 'apps-master-data-product-list',
    icon: 'FileIcon',
    resource: subjectAbility.MdProduct,
    action: 'read'
  },
  {
    title: 'apps.masterDataProductBrand.moduleName',
    route: 'apps-master-data-product-brand-list',
    icon: 'FileIcon',
    resource: subjectAbility.MdProductBrand,
    action: 'read'
  },
  {
    title: 'apps.masterDataProductCategory.moduleName',
    route: 'apps-master-data-product-category-list',
    icon: 'FileIcon',
    resource: subjectAbility.MdProductCategory,
    action: 'read'
  },
  {
    title: 'apps.masterDataSeaport.moduleName',
    route: 'apps-master-data-seaport-list',
    icon: 'FileIcon',
    resource: subjectAbility.Seaport,
    action: 'read'
  },
  {
    title: 'apps.masterDataAirport.moduleName',
    route: 'apps-master-data-airport-list',
    icon: 'FileIcon',
    resource: subjectAbility.Airport,
    action: 'read'
  },
  {
    title: 'apps.masterDataUnit.moduleName',
    route: 'apps-master-data-unit-list',
    icon: 'FileIcon',
    resource: subjectAbility.MdUnit,
    action: 'read'
  },
  {
    title: 'apps.masterDataTax.moduleName',
    route: 'apps-master-data-tax-list',
    icon: 'FileIcon',
    // resource: 'Auth',
    resource: subjectAbility.MdTax,
    action: 'read'
  },
  {
    title: 'apps.masterDataTag.moduleName',
    route: 'apps-master-data-tag-list',
    icon: 'FileIcon',
    resource: subjectAbility.MdTag,
    action: 'read'
  }
]
