import { subjectAbility } from '@/constant'

/*
 * title : get from @/libs/i18n/locales/[id / en].json
 * in purpose to support multi language
 */

export default [
  {
    header: 'Report',
    resource: subjectAbility.Report,
    action: 'read'
  },
  {
    title: 'apps.reportLedger.moduleName',
    route: 'apps-report-ledger',
    icon: 'FileTextIcon',
    resource: subjectAbility.ReportLedger,
    action: 'read'
  }
]
